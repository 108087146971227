@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.hero-section {
    box-sizing: border-box;
    // padding: 50px 0;
    // background: url('../../assets/hero/heroBg.jpg')  no-repeat center center fixed;
    background: url("../../assets/hero/heroBg.jpg") no-repeat scroll top center/cover;
    height: 100vh;
    min-height: 850px;
    // @media screen and (max-width: 768px) {
    //     min-height: 0;
    // }
    padding-left: 25px;
    padding-right: 25px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .heroText {
        margin:  50px 0;
    }

    .bgOverlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color:rgba(0,0,0,0.5);
        z-index: -1;
    }
}

.titleSponsor {
    width: 40vw;
    max-width: 200px;
}

.presentedByWrapper {
    width: 20%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
        color: white;
        font-family: 'Montserrat', sans-serif;
    }
    .presentedBy {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        // @media screen and (max-width: 768px) {
        //     flex-direction: column;
        // }
        .imgWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            margin: 0 10px;
            img {
                // flex-grow: 1;
                flex-basis: 0;
                max-height: 100px;
            }
        }
    }
}