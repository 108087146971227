.section-about-brivia {
    // padding-bottom: 0;
    background: var(--header-bg-color);
    // background: white;

    * {
        color: white;
    }

    .swiper-slide {
        img {
            width: 250px;
            height: 250px;
            object-fit: cover;
        }
    }

    .headingContainer {
        border-radius: 16px;
        background-image: -webkit-linear-gradient(left, #FCEDB7, #FCF9E2, #E0CA98, #FBF1C2);
        padding: 0 5px;
        .headingWrapper {
            background-image: url('../../assets/headingBg.jpg');
            background-size: cover;
            border-radius: 16px;
            .heading {
                text-align: center;
                text-transform: uppercase;
                background: -webkit-linear-gradient(left, #FCEDB7, #FCF9E2, #E0CA98, #FBF1C2);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 5px 0;
                padding: 5px 0;
            }
        }
        
    }

    .content {
        margin-top: 50px;
        p {
            font-family: 'Raleway', sans-serif;
            font-weight: 400;
        }
        h3 {
            text-align: center;
            text-transform: uppercase;
        }
    }

    .text-center {
        text-align: center;
        margin-top: 100px;
    }

}