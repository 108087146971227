.section-redeem {
    * {
        color: black;
    }
    .seatingCaution {
        padding: 0;
        font-style: italic;
        color: grey;
        font-size: 12px;
        margin: 5px 0;
    }
    select {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
        background-position: 100%;
        background-repeat: no-repeat;
        padding-right: 50px;
    }
    .headingContainer {
        border-radius: 16px;
        background-image: -webkit-linear-gradient(left, #FCEDB7, #FCF9E2, #E0CA98, #FBF1C2);
        padding: 0 5px;
        .headingWrapper {
            background-image: url('../../assets/headingBg.jpg');
            border-radius: 16px;
            .heading {
                text-align: center;
                text-transform: uppercase;
                background: -webkit-linear-gradient(left, #FCEDB7, #FCF9E2, #E0CA98, #FBF1C2);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 5px 0;
                padding: 5px 0;
            }
        }
        
    }
    
    .contact-container {
        .purchaser {
            // margin-top: 50px;
            input {
                transition: border 0.25s;
            }
            .contactInvalid {
                border: 1px solid red;
            }
        }
        button {
            width: 100%;
            height: 50px;
            border: none;
            outline: none;
            border-radius: 16px;
            color: white;
            background: #0c53a0;
            margin-top: 25px;
            // text-transform: uppercase;
            cursor: pointer;
            transition: all 0.25s;
        }
    }

}